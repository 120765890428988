import React from 'react';

import bgImage from '../../../assets/img/rd-bg1.png';
import Link from '../Link/Link';

import medium from '../../../assets/img/mediumlogo.jpeg';
import npm from '../../../assets/img/npmlogo.png';
import github from '../../../assets/img/github.png';

const footer = () => {
  return (
    <footer className="overlay" id='footer'>
      <div id="others">
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>More...</span> Time consuming
          </h3>
        </div>
        <div className="container">
          <div className="row mb-5">
            <div className='col-lg-4 mb-3'>
              <img src={medium} className='work-img' alt='Blog 1' />
            </div>
            <div className='col-lg-8 mb-9'>
            <ul className='list-group quick-links'>
                <li>
                  <a target="_blank" href='https://medium.com/front-end-weekly/host-your-react-app-with-gitlab-c3ef2f93d0a2'> - Host your React-App with Gitlab : “Life begins at the end of your comfort zone” — Neale Donald Walsch</a>
                </li>
                <li>
                  <a target="_blank" href='https://medium.com/@rajdeepdeb/read-json-file-in-gbs-b7b8acefbc7d'> - Read JSON file in GBs : “Working in node and we love JSON.”</a>
                </li>
                <li>
                  <a target="_blank" href='https://medium.com/front-end-weekly/dynamically-load-external-js-css-angular-7edf2d5e1639'> - Callback for loading external JS/CSS | Angular</a>
                </li>
                <br />
                <li>
                  <a target="_blank" href='https://medium.com/@rajdeepdeb'>More...</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className='col-lg-4 mb-3'>
              <img src={github} className='work-img' alt='Blog 1' />
            </div>
            <div className='col-lg-8 mb-9'>
            <ul className='list-group quick-links'>
                <li>
                  <a target="_blank" href='https://github.com/itsrts/pptviewer-android'> - PPT-Viewer : A ppt viewer library for android, easy integration</a>
                </li>
                <li>
                  <a target="_blank" href='https://github.com/itsrts/Authenticator'> - Authenticator : A fork of Authenticator APP with prefix support”</a>
                </li>
                <li>
                  <a target="_blank" href='https://github.com/itsrts/eventbus-android'> - EventBus : A single file event bus for Android, no fuss</a>
                </li>
                <br />
                <li>
                  <a target="_blank" href='https://github.com/itsrts'>A lot more...</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className='col-lg-4 mb-3'>
              <img src={npm} className='work-img' alt='Blog 1' />
            </div>
            <div className='col-lg-8 mb-9'>
            <ul className='list-group quick-links'>
                <li>
                  <a target="_blank" href='https://www.npmjs.com/package/letsrequire'> - letsrequire : Hot require/relod your dependencies, no restart of the application server</a>
                </li>
                <li>
                  <a target="_blank" href='https://www.npmjs.com/package/big-json-reader'> - big-json-reader : Read a large JSON array from file”</a>
                </li>
                <li>
                  <a target="_blank" href='https://www.npmjs.com/package/api-ext'> - api-ext : A minimul API framework to build APIs with nodejs</a>
                </li>
                <br />
                <li>
                  <a target="_blank" href='https://www.npmjs.com/settings/itsrts/packages'>Not so more :)</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-12 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title'>
              <h6>Wanna get in touch..!!</h6>
            </div>
            <div className='footer-content'>
              <p className=''>
                <small>E-mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:hi@rajdeepdeb.me" target="_blank">hi@rajdeepdeb.me</a></small>
              </p>
              <p className=''>
                <small>npm &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.npmjs.com/~itsrts" target="_blank">npmjs.com/~itsrts</a></small>
              </p>
              <p className=''>
                <small>Github &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://github.com/itsrts" target="_blank">github.com/itsrts</a></small>
              </p>
              <p className=''>
                <small>LinkedIn &nbsp;&nbsp;<a href="https://www.linkedin.com/in/rajdeepdeb/" target="_blank">linkedin.com/in/rajdeepdeb</a></small>
              </p>
              {/* <div className='social-media mt-4'>
                <a href='https://www.facebook.com/hirajdeepdeb' target="_blank" className='text-light'>
                  <i className='fab fa-facebook-f mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-twitter mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-instagram mr-4' />
                </a>
                <a href='!#' className='text-light'>
                  <i className='fab fa-github' />
                </a>
              </div> */}
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 mb-5'>
            <div className='footer-title'>
              <h6>More About Me</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small style={{ color: '#d0d0d0' }}>
                  I'm a foodieee and I am confused to be proud about it.
                  I love working out and sweat all the bullshit.<br />
                  I can't live without music and I'm a big Netflix fan. I drive rash but not NFS.
                  {/* I love to play Guitar, Casio and Drums...once in a while. */}
                </small>
              </p>
              {/* <button className='btn btn-sm btn-primary rounded-0'>
                Learn more
              </button> */}
            </div>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Hit Rewind</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <Link target='me'>ME-WHO</Link>
                </li>
                <li>
                  <Link target='tech'>Tech</Link>
                </li>
                <li>
                  <Link target='works'>Works</Link>
                </li>
                <li>
                  <Link target='focus'>Focus</Link>
                </li>
                <li>
                  <Link target='contact'>Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ backgroundImage: `url(${bgImage})` }} className="overlay">
        <div className=' container text-light'>
          <br />
          <br />
          <h2 className='title'>RAJDEEP DEB</h2>
          <h5 className='sub-title mb-4'>
            And I repeat, I'm not a relative of .deb file or the debian package.
            </h5>
          <br />
          <br />
        </div>
      </div> */}
      <div className='bottom-footer'>
        {/* <small>© All Right Reserved. Design By Mohamed Azouaoui</small> */}
      </div>
    </footer>
  );
};

export default footer;
