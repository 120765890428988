import React, { Fragment } from 'react';

import Home from './Home/Home';
import Focus from './Focus/Focus';
import Tech from './Tech/Tech';
import Facts from './Facts/Facts';
import Me from './Me/Me';
import Contact from './Contact/Contact';
import MapMarker from './MapMarker/MapMarker';
import Works from './Works/Works';

const sections = () => {
  return (
    <Fragment>
      <Home />
      <Me />
      <Tech />
      <Works />
      <Focus />
      {/* <Facts /> */}
      {/* <Contact /> */}
      {/* <MapMarker /> */}
    </Fragment>
  );
};

export default sections;
