import React, { Fragment } from 'react';

import Section from '../../../HOC/Section';
import bgImage from '../../../assets/img/rd-bg1.png';
import Link from '../../UI/Link/Link';
import Header from '../../UI/Header/Header';

const home = () => {
  return (
    <Section id='home'>
      <div className="home">
        <Fragment >
        <div
          className='home-content p-5'
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className='intro container text-light'>
            <br />
            <br />
            <br />
            <h3 className='title'>RAJDEEP DEB</h3>
            <h2 className='sub-title mb-4'>
              Think of me as related to the Debian (.deb) package..? I wish so...
            </h2>
            {/* <br /> */}
            <br />
            {/* <h3 className='title'>AND I THINK</h3>
            <h2 className='sub-title mb-4'>
            Backend is the almighty, we BETTER get this soon
            </h2> */}
            {/* <Link target='about' classes='btn btn-primary rounded-0 mr-2'>
              Learn More
            </Link>
            <Link target='contact' classes='btn btn-light text-dark rounded-0'>
              Contact Us
            </Link> */}
          </div>
        </div>
        <Header className='' />
        </Fragment>
      </div>
    </Section>
  );
};

export default home;
