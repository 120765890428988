import React from 'react';

import Section from '../../../HOC/Section';

import core from '../../../assets/img/core.jpg';
import mobile from '../../../assets/img/mobile.jpg';
import backend from '../../../assets/img/backend.png';

const Me = () => {
  return (
    <Section id='me'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>What </span>I'm like
          </h3>
          <h6 className=' mr-auto ml-auto'>
            I am always open to learn and un-learn a few things
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={core} className='card-img-top' alt='me 1' />
                <div className='card-body'>
                  <h5 className='card-title'>Programing at core</h5>
                  <p className='card-text'>
                    I love to writing small independent scripts helping me in day-2-day stuff.
                    It's remarkable how it makes us think with perpective.
                    It's just fun...
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={mobile} className='card-img-top' alt='me 2' />
                <div className='card-body'>
                  <h5 className='card-title'>Mobility in the mind</h5>
                  <p className='card-text'>
                    It's is quite facsinating what we can deliver to a user.
                    With so many mobile devices. We can't anticipate what's coming NEXT..!!
                    So keep looking out..
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4 mb-3'>
              <div className='card rounded-0'>
                <img src={backend} className='card-img-top' alt='me 3' />
                <div className='card-body'>
                  <h5 className='card-title'>Backend and Infra</h5>
                  <p className='card-text'>
                    Infrastructure as a service is growing everyday. They save time, money and resources. I try to keep myself updated with what's coming next.
                  </p>
                  {/* <a href='#!' className='btn btn-sm btn-primary'>
                    Read More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Me;
