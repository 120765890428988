import React from 'react';

import Section from '../../../HOC/Section';

import amazon from '../../../assets/img/AmazonLogo.jpg';
import buzznog from '../../../assets/img/buzznogO.png';
import concirrus from '../../../assets/img/concirrus.png';
import paytm from '../../../assets/img/paytmO.png';
import collab8 from '../../../assets/img/collab8.png';
import finalrevise from '../../../assets/img/finalrevise.png';
import goparties from '../../../assets/img/gpO.png';
import dashapp from '../../../assets/img/dash.png';
import rd from '../../../assets/img/rd.png';

let arr = [{
  "index" : 1,
  "link" : "https://logistics.amazon.com",
  "title" : "Own your success",
  "description" : "Focusing on low startup costs, built-in demand, and logistics experience.<br />Amazon Delivery Service Partners in one of the fastest-growing industries in the world.",
  "image" : amazon
}, {
  "index" : 2,
  "link" : "https://www.concirrus.com",
  "title" : "Powering the future of insurance",
  "description" : "This project screams data and I explored different ways to play with tera-bytes of it.<br />Near-Real-Time analytics, Notifications and Alerts... Most Awesome project till date.",
  "image" : concirrus
}, {
  "index" : 3,
  "link" : "https://paytminsurance.co.in/",
  "title" : "Simple & Convenient",
  "description" : "My first step into the world of chaos and I loved it. Everything on to the Next-Level.<br />Data Sanity, Integrations, High traffic, and the list goes on. ",
  "image" : paytm
}, {
  "index" : 4,
  "link" : "https://goparties.com",
  "title" : "Discover Entertainment..!!",
  "description" : "The introduction to the backend. Engaged to Android but fall in love with NodeJS.<br />This new world was infinite for me. AWS, NoSQL, all \"<i>over my head </i>\"",
  "image" : goparties
}, {
  "index" : 5,
  "link" : "#",
  "title" : "You are looking at it",
  "description" : "A long pending update on the website was needed, moving blogs to medium.com.<br />Update to React from plain HTML, implement a smooth CI/CD with gitlab, etc.",
  "image" : rd
}, {
  "index" : 6,
  "link" : "https://play.google.com/store/apps/details?id=com.Activities.collab8&hl=en",
  "title" : "State-of-the-Art collaboration",
  "description" : "My only project working on a proprietary tech to stream display wirelessly.<br />I built the Android PPT Viewer library, also integrating FTP server and Media Player.",
  "image" : collab8
}, {
  "index" : 7,
  "link" : "http://finalrevise.com",
  "title" : "Revisions are never Final",
  "description" : "This project helps students in their preparation of different exams.<br />Entrance Exams | School boards Exams | Government Exams | Placement Papers.",
  "image" : finalrevise
}, {
  "index" : 8,
  "link" : "https://dash-app.gitlab.io",
  "title" : "Your own Gitlab Monitor",
  "description" : "Started with a few python scripts to automate gitlab actions, this came out as a self-sufficient web application. All thanks to gitlab v4 APIs.",
  "image" : dashapp
}, {
  "index" : 9,
  "link" : "https://buzznog.com",
  "title" : "Totally Buzzing..!!",
  "description" : "This project tops my list of Android Applications. My love for music payed off.<br />Music/Video Player, Social Media Feeds, Animations and everything.",
  "image" : buzznog
},];

arr.sort((a, b) => a.index - b.index);

const Works = () => {
  return (
    <Section id='works'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>The </span>Collectibles
          </h3>
          <h6 className=' mr-auto ml-auto'>
            I kind of struggle with the definition of FullStack... but again... I don't care
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            {
              arr.map(w => {
                return <div className='col-lg-4 mb-3'>
                <div className='rounded-0 work-card text-center'>
                  <a href={w.link} target="_blank"><img src={w.image} className='work-img' alt='Blog 1' /></a>
                  <div className='card-body'>
                  <h5 className='card-title'><a href={w.link} target="_blank">{w.title}</a></h5>
                    <p className='card-text' dangerouslySetInnerHTML={{__html : w.description}} />
                    {/* <a href='#!' className='btn btn-sm btn-primary'>
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
              })
            }
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Works;
