import React from 'react';

import Section from '../../../HOC/Section';

const Tech = () => {
  return (
    <Section id='tech'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>My love </span> for Stack
          </h3>
          <h6 className=' mr-auto ml-auto'>
            A list, I built over the years...
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-hat-wizard' style={{color: '#429ad8'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>MEAN / MERN</h5>
                  <p className='service-description'>
                    Mongo-Express-Angular/React-Node <br />
                    The combination has NO LIMIT
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-seedling' style={{color: '#7bb654'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Java, Spring Boot</h5>
                  <p className='service-description'>
                    "Old is Gold" well the saying seems fitting to me in case of "Java Spring Boot". It just works.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-prescription-bottle' style={{color: '#426c98'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Python/Nodejs Scripting</h5>
                  <p className='service-description'>
                    Scripting laguage has their own perks. You can do SHIT and no one cares, the job is done.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fa fa-mobile' style={{color: '#ffbb00'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>Mobile Tech</h5>
                  <p className='service-description'>
                    Be it Android or iOS, mobile world is fascinating. So much to reach, and still looking for more.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-database' style={{color: '#428842'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>SQL/NoSQL Databases</h5>
                  <p className='service-description'>
                    If someone is living in a dilemma to choose b/w the two, let me know.
                    I can figure it out for you.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-3'>
              <div className='service-box d-flex'>
                <div className='service-icon mr-4'>
                  <i className='fas fa-robot' style={{color: '#fd6d25'}}/>
                </div>
                <div className='service-body'>
                  <h5 className='service-title'>CI/CD and automation</h5>
                  <p className='service-description'>
                    I just loved working with automation. It is the fist step towards the necessity of softwares. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Tech;
