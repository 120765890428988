import React from 'react';

import Section from '../../../HOC/Section';
import focusImage from '../../../assets/img/portfolio-2b.jpg';
import docker from '../../../assets/img/docker.png';
import flutter from '../../../assets/img/flutterdart.png';

const Focus = () => {
  return (
    <Section id='focus'>
      <div className='container pt-2 pb-5'>
        <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Currently </span>Focusing on
          </h3>
          <h6 className=' mr-auto ml-auto'>
            I'm one of the guys who "Move fast and BUILD things"
          </h6>
        </div>
        <div className='section-content'>
          <div className='row'>
            <div className='col-lg-6 mb-6'>
              <div className='focusImage'>
                <img src={flutter} alt='focus company' />
              </div>
            </div>
            <div className='col-lg-6 mb-6'>
              <div className='focusImage'>
                <img src={docker} alt='focus company' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='focusImage'>
                <img src={focusImage} alt='focus company' />
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              {/* <h3 className='focus-title'>About us</h3> */}
              <div className='focus-description'>
                <p>
                  I love ReactJS. I tried VueJS and Angular/AngularJS as well, but React is my first preference for now.
                </p>
                <p>
                  Backend technologies is an ever-changing domain. I'm currently focused with what all is possible with Docker, Kubernetes, CI/CD pipelines and similar stuff.
                </p>
                <p>
                  Talking about the mobile technologies... Flutter has really got under my skin. I did develop a simple Login/Signup App, a Home page with GridView. I must say I'm impressed with the animation support and view management, the tools are really start to ease out the app designing process.
                </p>
                {/* <button className='btn btn-primary rounded-0'>Read More</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Focus;
